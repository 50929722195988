<template>
  <v-container>
    <v-card class="mx-auto" max-width="1200px">
      <v-list two-line>
        <template v-for="(item, index) in items">
          <v-subheader v-if="item.header" :key="index" inset>
            {{ item.header }}
          </v-subheader>

          <v-divider v-else-if="item.divider" :key="index" inset></v-divider>

          <v-list-item v-else :key="index">
            <v-list-item-avatar size="84">
              <v-img
                :src="`${publicPath}images/${item.name.split(' ')[0]}.jpg`"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="item.name"></v-list-item-title>
              <v-list-item-subtitle v-html="item.title"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      failed_image: false,
      items: [
        {
          header: "Principal Investigators (PI)",
        },
        { divider: true },
        {
          name: "Midori Kitagawa",
          title:
            "Research Professor - School of Natural Sciences and Mathematics, UT Dallas",
        },
        {
          name: "Michael Kesden",
          title: "Associate Professor in the Department of Physics, UT Dallas",
        },
        {
          name: "Mary L. Urquhart",
          title:
            "Associate Professor and Head of the Department of Science/Mathematics Education and Affiliate Associate Professor of Physics, UT Dallas",
        },
        {
          name: "Roger Malina",
          title:
            "Professor - School of ATEC and the Department of Physics, UT Dallas",
        },

        { divider: true },
        {
          header: "Former members of VIGOR",
        },
        { divider: true },
        {
          name: "Mike Ngoc Tran",
          title: "Research Assistant",
        },
        {
          name: "Kira Rong Jin",
          title: "Research Assistant",
        },
        {
          name: "Monisha Elumalai",
          title: "Research Assistant",
        },
        {
          name: "Thulasi Pillai",
          title: "Research Assistant",
        },
      ],
    };
  },
};
</script>
